export var getRoomUIState = function(context) {
    if (!context.participant) {
        return {
            type: "offline"
        };
    }
    if (context.participant.queuePos > 0) {
        return {
            type: "queued"
        };
    }
    if (context.activeSession) {
        return {
            type: "live",
            streamVisibility: getStreamVisibility(context)
        };
    }
    if ((context.isStaff || isMod(context.participant)) && isRoomInPreview(context)) {
        return {
            type: "preview"
        };
    }
    return {
        type: "offline"
    };
};
var getStreamVisibility = function(context) {
    var ref, ref1;
    if (!context.contentStream.relay && !((ref = context.contentStream.screenSharingUser) === null || ref === void 0 ? void 0 : ref.userID)) {
        return "hidden";
    }
    if (context.contentStream.webSocketURL || ((ref1 = context.contentStream.screenSharingUser) === null || ref1 === void 0 ? void 0 : ref1.userID)) {
        return "visible";
    }
    if (context.contentStream.accessSummary.pending) {
        return "pending";
    }
    if (context.contentStream.accessSummary.denialReason) {
        return "tv-auth-block";
    }
    return "visible";
};
var isMod = function(participant) {
    return participant.permissions.some(function(permission) {
        return permission.enabled;
    });
};
var isRoomInPreview = function(context) {
    var ref;
    return !!context.contentStream.relay || !!((ref = context.contentStream.screenSharingUser) === null || ref === void 0 ? void 0 : ref.userID) || getStageMemberCount(context.stage) > 0;
};
var getStageMemberCount = function(stage) {
    return Object.keys(stage.members).length;
};
