export enum LocalStorageKey {
    Uid = 'playback_anon_id',
    StreamVolume = 'playback_stream_volume',
    StageVolume = 'playback_stage_volume',
    PinnedMessages = 'playback_pinned_messages',
    ChatNotificationsDisabled = 'playback_chat_notifications_disabled',
    SupportEnabled = 'playback_support_enabled',
    MessagesPane = 'playback_messages_pane',
    MessagesOpen = 'playback_messages_open',
    TvAuthConnections = 'playback_tv_auth_connections',
    LastAuthMethod = 'playback_last_auth_method',
    WatchTime = 'playback_watch_time',
    AudioInputPermissionStatus = 'playback_audio_input_permission_status',
    VideoInputPermissionStatus = 'playback_video_input_permission_status',
    ModBroadcastFilters = 'playback_mod_broadcast_filters',
    ModRoomFilters = 'playback_mod_room_filters',
    DemoProgress = 'playback_demo_progress',
    VncModTool = 'playback_vnc_modtool',
    OnboardingVanityStep = 'playback_onboarding_vanity_step',
    OnboardingPlaybackUItems = 'playback_onboarding_playback_u_items',
    OnboardingShareImage = 'playback_onboarding_share_image',
}

export const localStorageGet = <T = string>(key: LocalStorageKey | string, defaultValue?: T): T => {
    if (typeof window === 'undefined') {
        return defaultValue;
    }

    const value = localStorage.getItem(key);

    if (!value) {
        return defaultValue;
    }

    try {
        return JSON.parse(value);
    } catch (e) {
        return <T>(value as unknown);
    }
};

export const localStorageSet = <T = string>(key: LocalStorageKey | string, value: T) => {
    if (typeof window === 'undefined') {
        return;
    }

    const json = typeof value === 'string' ? value : JSON.stringify(value);
    localStorage.setItem(key, json);
};

export const localStorageDelete = (key: LocalStorageKey | string) => {
    if (typeof window === 'undefined') {
        return;
    }

    localStorage.removeItem(key);
};
