import create from "zustand";
var configureVolumeStore = function(initialVolume) {
    return function(set) {
        return {
            volume: initialVolume,
            muted: false,
            setVolume: function(volume) {
                return set({
                    volume: volume,
                    muted: volume === 0
                });
            },
            toggleMuted: function(muted) {
                return set(function(state) {
                    var newMuted = muted !== null && muted !== void 0 ? muted : !state.muted;
                    return {
                        muted: newMuted,
                        volume: !newMuted && state.volume === 0 ? 50 : state.volume
                    };
                });
            }
        };
    };
};
export var useStageVolume = create(configureVolumeStore(100));
export var useStreamVolume = create(configureVolumeStore(50));
export function useVolumeMode() {
    var stage = useStageVolume();
    var stream = useStreamVolume();
    var streamMuted = stream.muted || stream.volume === 0;
    var stageMuted = stage.muted || stage.volume === 0;
    if (stageMuted && streamMuted) {
        return "muted";
    }
    if (stageMuted || streamMuted) {
        return "partial";
    }
    return "enabled";
}
