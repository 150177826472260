function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n    border-radius: ",
        ";\n    overflow: hidden;\n    transform: translate3d(0, 0, 0);\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { rem } from "polished";
import { css } from "styled-components";
export var rounded = function(px) {
    return css(_templateObject(), rem(px));
};
