import { createSlice } from "@reduxjs/toolkit";
import { setRemoteStream } from "./sharedActions";
export var initialState = {
    remoteStream: null,
    subscribeError: false
};
var contentSlice = createSlice({
    name: "content",
    initialState: initialState,
    reducers: {
        removeRemoteStream: function removeRemoteStream(state) {
            state.remoteStream = null;
        },
        setSubscribeError: function setSubscribeError(state, action) {
            state.subscribeError = action.payload;
        }
    },
    extraReducers: function(builder) {
        builder.addCase(setRemoteStream, function(state, action) {
            var stream = action.payload;
            state.remoteStream = stream;
        });
    }
});
var _actions = contentSlice.actions;
export var removeRemoteStream = _actions.removeRemoteStream, setSubscribeError = _actions.setSubscribeError;
export * from "./sharedActions";
export default contentSlice.reducer;
