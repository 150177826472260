export var SignalType;
(function(SignalType) {
    SignalType["Heartbeat"] = "heartbeat";
    SignalType["Identified"] = "identified";
    SignalType["PeerCreate"] = "peerCreate";
    SignalType["PeerClose"] = "peerClose";
    SignalType["PeerJoin"] = "peerJoin";
    SignalType["PeerOffer"] = "peerOffer";
    SignalType["PeerAnswer"] = "peerAnswer";
    SignalType["PeerTrickle"] = "peerTrickle";
    SignalType["RelayClose"] = "relayClose";
    SignalType["RelayPeerClose"] = "relayPeerClose";
    SignalType["RoomState"] = "roomState";
    SignalType["RoomPermissionsUpdate"] = "roomPermissionsUpdate";
    SignalType["RoomMutedUpdate"] = "roomMutedUpdate";
    SignalType["SubsQueueSizeUpdate"] = "subsQueueSizeUpdate";
    SignalType["ParticipantUnregister"] = "participantUnregister";
    SignalType["ParticipantName"] = "participantName";
    SignalType["ParticipantCamMic"] = "participantCamMic";
    SignalType["ParticipantCam"] = "participantCam";
    SignalType["ParticipantMic"] = "participantMic";
    SignalType["ParticipantUpdate"] = "participantUpdate";
    SignalType["ParticipantsUpdate"] = "participantsUpdate";
    SignalType["ParticipantAuth"] = "participantAuth";
    SignalType["ParticipantMute"] = "participantMute";
    SignalType["ContentStreamUpdate"] = "contentStreamUpdate";
    SignalType["ContentStreamMetadata"] = "contentStreamMetadata";
    SignalType["ContentStreamPublisherMetrics"] = "contentStreamPublisherMetrics";
    SignalType["ContentStreamSubscriberMetrics"] = "contentStreamSubscriberMetrics";
    SignalType["VoteCast"] = "voteCast";
    SignalType["VoteState"] = "voteState";
    SignalType["VoteStreakState"] = "voteStreakState";
    SignalType["StageUpdate"] = "stageUpdate";
    SignalType["StageInvite"] = "stageInvite";
    SignalType["StageRSVP"] = "stageRSVP";
    SignalType["StageKick"] = "stageKick";
    SignalType["StageRequest"] = "stageRequest";
    SignalType["StageAccept"] = "stageAccept";
    SignalType["QualityIndicator"] = "qualityIndicator";
    SignalType["AuthError"] = "authError";
    SignalType["RoomSessionUpdate"] = "roomSessionUpdate";
    SignalType["ScreenShareState"] = "screenShareState";
    SignalType["ScreenShareStart"] = "screenShareStart";
    SignalType["ScreenShareStop"] = "screenShareStop";
    SignalType["StudioMenuUpdate"] = "studioMenuUpdate";
})(SignalType || (SignalType = {}));
