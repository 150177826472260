import axios from 'axios';
import { isProduction } from '../utils/env';

// TODO move to playback.tv
export const API_HOST = isProduction()
    ? 'https://api.getplayback.com'
    : 'https://api.getplayback.dev';

interface GetDailyTokenRequest {
    roomID: string;
    userID: string;
}

interface GetDailyTokenResponse {
    token: string;
}

export const apiGetDailyToken = async (
    roomID: string,
    userID: string
): Promise<GetDailyTokenResponse> => {
    const resp = await axios({
        method: 'GET',
        url: `${API_HOST}/daily`,
        responseType: 'json',
        params: <GetDailyTokenRequest>{
            roomID,
            userID,
        },
    });

    return resp.data.token;
};
