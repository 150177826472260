import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    userSlice as sharedUserSlice,
    initialState,
    extraReducers as sharedExtraReducers,
    UserState,
} from '@internal/state/user';
import { HYDRATE } from 'next-redux-wrapper';

const { name, caseReducers } = sharedUserSlice;

const userSlice = createSlice({
    name,
    initialState,
    reducers: caseReducers,
    extraReducers: (builder) => {
        sharedExtraReducers(builder);
        // eslint-disable-next-line
        // @ts-ignore HYDRATE is missing types
        builder.addCase(HYDRATE, (state, action: PayloadAction<{ user: UserState }>) => {
            if (state.instance) {
                return state;
            }

            // Only merge hydration data if user is set. Otherwise
            // doing so will break user authentication by overriding
            // magic redirect data
            if (action.payload.user?.instance) {
                return {
                    ...state,
                    ...action.payload.user,
                };
            }

            return state;
        });
    },
});

export type { UserState };

export { initialState };

export const { reducer } = userSlice;
