import { createAction } from '@reduxjs/toolkit';
import { TrackingEvent } from '@internal/tracking/types';
import { AppState } from './appSlice';

export const init =
    createAction<
        Pick<
            AppState,
            | 'mobile'
            | 'osName'
            | 'osVersion'
            | 'browserName'
            | 'browserVersion'
            | 'deviceType'
            | 'deviceVendor'
            | 'deviceModel'
        >
    >('app/init');

export const track = createAction<TrackingEvent>('app/track');

export const pageView = createAction<{ title: string } & (Record<string, unknown> | undefined)>(
    'app/pageView'
);

export const identifyUser = createAction<unknown>('app/identifyUser');
