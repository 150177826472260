import { Room } from '@internal/api/types';
import { ScheduledSession } from '@internal/api/types/roomSession';
import {
    Broadcast,
    ScheduledEvent,
    ScheduledEventType,
    Sport,
} from '@internal/api/types/scheduledEvents';

export const dateFormatSameDay = new Intl.DateTimeFormat('en', {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
});

export const dateFormatWeekday = new Intl.DateTimeFormat('en', {
    weekday: 'long',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
});

export const dateFormatPast = new Intl.DateTimeFormat('en', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
    year: 'numeric',
});

export const dateFormatFuture = new Intl.DateTimeFormat('en', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
});

const ONE_HOUR_MS = 1000 * 60 * 60;
export const isStartingSoon = (timestamp: Date | string | number): boolean => {
    const startDate = !(timestamp instanceof Date) ? new Date(timestamp) : timestamp;
    const currentDate = new Date();
    const startTime = startDate.getTime();
    const currentTime = currentDate.getTime();

    return startTime > currentTime && startTime - currentTime < ONE_HOUR_MS;
};

const dateStringFormat = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
});

export const getFutureDate = (date: Date, daysAhead: number) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + daysAhead);

    return newDate;
};

export const formatStartDate = (startTime: string | number, ended = false) => {
    const startDate = new Date(startTime);

    if (ended) {
        return dateFormatPast.format(startDate);
    }

    const currentDate = new Date();
    const now = currentDate.getTime();
    const time = startDate.getTime();
    const elapsed = time - now;

    if (elapsed < 0) {
        return 'Live';
    }

    const currentDateStr = dateStringFormat.format(currentDate);
    const startDateStr = dateStringFormat.format(startDate);
    if (startDateStr === currentDateStr) {
        return `Today ${dateFormatSameDay.format(startDate)}`;
    }

    const tomorrow = getFutureDate(currentDate, 1);
    if (startDateStr === dateStringFormat.format(tomorrow)) {
        return `Tomorrow ${dateFormatSameDay.format(startDate)}`;
    }

    // use currentDateStr to base comparison on midnight of current date
    const oneWeekAway = getFutureDate(new Date(currentDateStr), 7);
    if (startDate.getTime() < oneWeekAway.getTime()) {
        return dateFormatWeekday.format(startDate);
    }

    return dateFormatFuture.format(startDate);
};

type SportTitleConfig = { separator: string; homeFirst: boolean };
const sportTitleConfigs: Partial<{ [key in Sport]: SportTitleConfig }> = {
    [Sport.Baseball]: { separator: 'at', homeFirst: false },
    [Sport.Basketball]: { separator: 'at', homeFirst: false },
    [Sport.Football]: { separator: 'at', homeFirst: false },
    [Sport.Hockey]: { separator: 'at', homeFirst: false },
    [Sport.Soccer]: { separator: 'vs.', homeFirst: true },
    [Sport.Tennis]: { separator: 'vs.', homeFirst: true },
};

export const getEventTitle = (event: ScheduledEvent, abbreviate = false) => {
    if (!event) {
        return null;
    }

    if (event.scheduledEventType === ScheduledEventType.Generic) {
        return event.title;
    }

    const awayName = abbreviate ? event.awayTeamAbbv : event.awayTeamStr;
    const homeName = abbreviate ? event.homeTeamAbbv : event.homeTeamStr;

    const config = sportTitleConfigs[event.sport];
    if (!config) {
        return `${awayName} @ ${homeName}`;
    }

    if (config.homeFirst) {
        return `${homeName} ${config.separator} ${awayName}`;
    }

    return `${awayName} ${config.separator} ${homeName}`;
};

export const getBroadcastLabel = (networkStr: string, type?: Broadcast['type']) => {
    return type === 'home' || type === 'away'
        ? `${type === 'home' ? 'Home' : 'Away'}: ${networkStr}`
        : networkStr;
};

export const formatSectionTitle = (startDate: Date) => {
    const currentDate = new Date();
    const now = currentDate.getTime();
    const time = startDate.getTime();
    const elapsed = time - now;

    if (elapsed < 0) {
        return 'Live';
    }

    if (elapsed >= 2 * 24 * 60 * 60 * 1000) {
        return startDate.toLocaleDateString(undefined, { month: 'long' });
    }

    const daysAway = startDate.getDate() - currentDate.getDate();
    if (daysAway === 0) {
        return 'Today';
    }

    if (daysAway === 1) {
        return 'Tomorrow';
    }

    return startDate.toLocaleDateString(undefined, { month: 'long' });
};

const getStartTime = (item: ScheduledSession | Room) => {
    return 'startTime' in item ? item.startTime : item.nextSession?.startTime;
};

const started = (item: ScheduledSession | Room) => {
    return 'started' in item ? item.started : item.nextSession?.started;
};

export function getGroupedSchedule(items: ScheduledSession[]): {
    sections: { [title: string]: ScheduledSession[] };
    sectionTitles: string[];
};
export function getGroupedSchedule(items: Room[]): {
    sections: { [title: string]: Room[] };
    sectionTitles: string[];
};
export function getGroupedSchedule(items: (ScheduledSession | Room)[]) {
    const sections: { [title: string]: (ScheduledSession | Room)[] } = {};
    const sectionTitles: string[] = [];

    items.forEach((item) => {
        if ('nextSession' in item && !item.nextSession) {
            return;
        }

        const startTime = getStartTime(item);
        if (!startTime) {
            return;
        }

        const itemStart = new Date(startTime);
        const sectionTitle = formatSectionTitle(itemStart);

        if (!sections[sectionTitle]) {
            sections[sectionTitle] = [];
        }

        sections[sectionTitle].push(item);

        if (!sectionTitles.includes(sectionTitle)) {
            sectionTitles.push(sectionTitle);
        }
    });

    return { sections, sectionTitles };
}

export const getEarlyStartMinutes = (event: ScheduledEvent | null = null, startTime: number) => {
    return event
        ? Math.round(new Date(event.startTime).getTime() - startTime) / (60 * 1000)
        : undefined;
};

export type ThumbnailOptions = { format?: 'svg' | 'png'; size?: 'sm' | 'md' | 'lg' };

export const getThumbnailUrl = (
    session: ScheduledSession,
    options: ThumbnailOptions | null = null
) => {
    return (
        session.thumbnailURL ||
        constructUrl(
            session.scheduledEvents?.[0]?.thumbnailURL || session.defaultThumbnailURL,
            options
        )
    );
};

function constructUrl(baseUrl: string, options: ThumbnailOptions | null = null) {
    if (!options) {
        return baseUrl;
    }

    const search = new URLSearchParams(options);
    return `${baseUrl}?${search.toString()}`;
}
