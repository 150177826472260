import { CreatorTier } from '@internal/api/types';
import dynamic from 'next/dynamic';
import { useAppSelector } from 'state/hooks';

const DynamicCreatorTerms = dynamic(() =>
    import('./CreatorTermsModal').then((m) => m.CreatorTermsModal)
);

const DynamicUserTerms = dynamic(() => import('./UserTermsModal').then((m) => m.UserTermsModal));

export const TermsOfServiceModals = () => {
    const userId = useAppSelector((state) => state.user.id);
    const isCreator = useAppSelector(
        (state) => state.user.instance && state.user.instance.creatorTier !== CreatorTier.None
    );

    if (!userId) {
        return null;
    }

    if (isCreator) {
        return <DynamicCreatorTerms />;
    }

    return <DynamicUserTerms />;
};
