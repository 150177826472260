export var getPollReactionType = function(index) {
    return "poll_vote_".concat(index);
};
export var getPoll = function(message) {
    var ref2;
    var pollAttachment = (ref2 = message.attachments) === null || ref2 === void 0 ? void 0 : ref2.find(function(attachment) {
        return attachment.type === "poll";
    });
    if ((pollAttachment === null || pollAttachment === void 0 ? void 0 : pollAttachment.type) !== "poll") {
        return;
    }
    var question = pollAttachment.question, choices = pollAttachment.choices;
    var ref1;
    var votes = choices.map(function(_, index) {
        var ref;
        return (ref1 = (ref = message.reaction_counts) === null || ref === void 0 ? void 0 : ref[getPollReactionType(index)]) !== null && ref1 !== void 0 ? ref1 : 0;
    });
    var ownVote = choices.findIndex(function(_, index) {
        var ref;
        return (ref = message.own_reactions) === null || ref === void 0 ? void 0 : ref.find(function(reaction) {
            return reaction.type === getPollReactionType(index);
        });
    });
    var _ended;
    return {
        id: message.id,
        question: question,
        choices: choices,
        votes: votes,
        ownVote: ownVote >= 0 ? ownVote : null,
        ended: (_ended = pollAttachment.ended) !== null && _ended !== void 0 ? _ended : false
    };
};
