"use strict";
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
var base = require("./basePalette").base;
/**
 * The default palette defines all of the required keys in a `SemanticPalette`.
 *
 * Naming convention: {intent}-{variant}-{target}--{state}
 */ var actions = {
    // Action Primary
    "action-primary-bg--disabled": base.primary["400"],
    "action-primary-bg--hover": base.primary["200"],
    "action-primary-bg--pressed": base.primary["200"],
    "action-primary-bg": base.primary["100"],
    "action-primary-fg--disabled": base.secondary["400"],
    "action-primary-fg--hover": base.tertiary["300"],
    "action-primary-fg--pressed": base.tertiary["300"],
    "action-primary-fg": base.tertiary["300"],
    // Action Secondary
    "action-secondary-bg--disabled": base.secondary["400"],
    "action-secondary-bg--hover": base.secondary["100"],
    "action-secondary-bg--pressed": base.secondary["100"],
    "action-secondary-bg": base.secondary["300"],
    "action-secondary-fg--disabled": base.primary["300"],
    "action-secondary-fg--hover": base.primary["100"],
    "action-secondary-fg--pressed": base.primary["100"],
    "action-secondary-fg": base.primary["100"],
    // Action Negative
    "action-negative-bg--disabled": base.negative["300"],
    "action-negative-bg--hover": base.negative["300"],
    "action-negative-bg--pressed": base.negative["300"],
    "action-negative-bg": base.negative["200"],
    "action-negative-fg--disabled": base.negative["200"],
    "action-negative-fg--hover": base.negative["100"],
    "action-negative-fg--pressed": base.negative["100"],
    "action-negative-fg": base.negative["100"],
    // Action Positive
    "action-positive-bg--disabled": base.positive["300"],
    "action-positive-bg--hover": base.positive["300"],
    "action-positive-bg--pressed": base.positive["300"],
    "action-positive-bg": base.positive["200"],
    "action-positive-fg--disabled": base.positive["200"],
    "action-positive-fg--hover": base.positive["100"],
    "action-positive-fg--pressed": base.positive["100"],
    "action-positive-fg": base.positive["100"],
    // Action Neutral
    "action-neutral-bg--disabled": base.neutral["300"],
    "action-neutral-bg--hover": base.neutral["300"],
    "action-neutral-bg--pressed": base.neutral["300"],
    "action-neutral-bg": base.neutral["200"],
    "action-neutral-fg--disabled": base.neutral["200"],
    "action-neutral-fg--hover": base.neutral["100"],
    "action-neutral-fg--pressed": base.neutral["100"],
    "action-neutral-fg": base.neutral["100"],
    // Action Negative Loud
    "action-negativeLoud-bg--disabled": base.negative["300"],
    "action-negativeLoud-bg--hover": base.negative["200"],
    "action-negativeLoud-bg--pressed": base.negative["200"],
    "action-negativeLoud-bg": base.negative["100"],
    "action-negativeLoud-fg--disabled": base.negative["200"],
    "action-negativeLoud-fg--hover": base.primary["100"],
    "action-negativeLoud-fg--pressed": base.primary["100"],
    "action-negativeLoud-fg": base.primary["100"],
    // Action Accent
    "action-accent-bg--disabled": base.accent["400"],
    "action-accent-bg--hover": base.accent["400"],
    "action-accent-bg--pressed": base.accent["400"],
    "action-accent-bg": base.accent["350"],
    "action-accent-fg--disabled": base.accent["250"],
    "action-accent-fg--hover": base.primary["100"],
    "action-accent-fg--pressed": base.primary["100"],
    "action-accent-fg": base.primary["100"],
    // Action Tertiary Transparent
    "action-tertiaryTransparent-bg--disabled": base["tertiary-transparent"]["25"],
    "action-tertiaryTransparent-bg--hover": base["tertiary-transparent"]["75"],
    "action-tertiaryTransparent-bg--pressed": base["tertiary-transparent"]["75"],
    "action-tertiaryTransparent-bg": base["tertiary-transparent"]["50"],
    "action-tertiaryTransparent-fg--disabled": base["primary"]["300"],
    "action-tertiaryTransparent-fg--hover": base.primary["100"],
    "action-tertiaryTransparent-fg--pressed": base.primary["100"],
    "action-tertiaryTransparent-fg": base.primary["100"],
    // Action Text
    "action-text": base.primary["100"],
    // Action Text Quiet
    "action-textQuiet": base.primary["300"],
    "action-textQuiet--hover": base.primary["200"]
};
var statuses = {
    // Status Primary
    "status-primary-bg": base.primary["100"],
    "status-primary-fg": base.tertiary["300"],
    // Status Secondary
    "status-secondary-bg": base.secondary["300"],
    "status-secondary-fg": base.primary["100"],
    // Status Positive
    "status-positive-bg": base.positive["200"],
    "status-positive-fg": base.positive["100"],
    // Status Negative
    "status-negative-bg": base.negative["200"],
    "status-negative-fg": base.negative["100"],
    // Status Negative Loud
    "status-negativeLoud-bg": base.negative["100"],
    "status-negativeLoud-fg": base.primary["100"],
    // Status Neutral
    "status-neutral-bg": base.neutral["200"],
    "status-neutral-fg": base.neutral["100"],
    // Status Neutral Loud
    "status-neutralLoud-bg": base.neutral["100"],
    "status-neutralLoud-fg": base.neutral["300"]
};
var texts = {
    "text-primary": base.primary["100"],
    "text-secondary": base.primary["200"],
    "text-tertiary": base.primary["300"],
    "text-error": base.negative["100"]
};
var surfaces = {
    "surface-popover": base.tertiary["100"],
    "surface-notification": base.tertiary["100"],
    "surface-overlay": base.tertiary["100"],
    "surface-overlay-secondary": base.secondary["400"],
    "surface-inlay": base.tertiary["200"],
    "surface-card": base.secondary["400"],
    "surface-card-secondary": base.tertiary["100"],
    "surface-selectable": base.tertiary["200"],
    "surface-selectable--hover": base.secondary["400"],
    "surface-selectable--selected": base.secondary["400"],
    "surface-background": base.tertiary["300"],
    "surface-background-secondary": base.tertiary["200"],
    "surface-background-tertiary": base.negative["400"]
};
var editables = {
    "editable-primary-bg": base.tertiary["200"],
    "editable-primary-fg": base.primary["100"],
    "editable-primary-fg--hover": base.primary["100"],
    "editable-primary-fg--error": base.negative["100"],
    "editable-primary-placeholder": base.primary["200"],
    "editable-primary-placeholder--error": base.negative["100"],
    "editable-primary-border--focused": base.primary["200"],
    "editable-primary-border--error--focused": base.negative["100"],
    "editable-primary-bg--hover": base.tertiary["300"],
    "editable-primary-bg--error": base.negative["300"]
};
module.exports.semantic = _objectSpread({}, actions, statuses, texts, surfaces, editables);
