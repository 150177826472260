export var ChannelType;
(function(ChannelType) {
    ChannelType["Room"] = "room";
    ChannelType["Support"] = "support";
    ChannelType["Mod"] = "mod";
})(ChannelType || (ChannelType = {}));
export var ReactionType;
(function(ReactionType) {
    ReactionType["Fire"] = "fire";
    ReactionType["Ice"] = "ice";
    ReactionType["Laughing"] = "laughing";
    ReactionType["Mad"] = "mad";
    ReactionType["Eyes"] = "eyes";
})(ReactionType || (ReactionType = {}));
export var MessageType;
(function(MessageType) {
    MessageType["Text"] = "text";
    MessageType["Reaction"] = "reaction";
})(MessageType || (MessageType = {}));
export var EphemeralMessageType;
(function(EphemeralMessageType) {
    EphemeralMessageType["SupportPrompt"] = "Support Prompt";
    EphemeralMessageType["SupportResolved"] = "Support Resolved";
})(EphemeralMessageType || (EphemeralMessageType = {}));
