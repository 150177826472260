import { Button } from '@internal/ui/components/Button';
import { Modal, ModalMain } from '@internal/ui/components/Modal';
import { semanticColor } from '@internal/ui/palette/semantic';
import { globalBodyPrimary, heading3 } from '@internal/ui/typography';
import { useRouter } from 'next/router';
import { rem } from 'polished';
import React, { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { setLoaderVisible } from 'state/app';
import { useAppDispatch } from 'state/hooks';
import styled from 'styled-components';

const ErrorFallback: React.FunctionComponent<FallbackProps> = () => {
    const router = useRouter();
    const dispatch = useAppDispatch();
    const recorder = !!router.query.recorder;

    useEffect(() => {
        if (recorder) {
            window.location.reload();
        }
    }, [recorder]);

    useEffect(() => {
        dispatch(setLoaderVisible(false));
    }, [dispatch]);

    if (recorder) {
        return null;
    }

    return (
        <Modal isOpen layout="dialog" aria-label="Something went wrong">
            <ModalMain>
                <Contents>
                    <Heading>Oops, looks like something went wrong!</Heading>
                    <Body>
                        The bug has been reported — we’ll fix it ASAP. Please reload the page to
                        continue.
                    </Body>
                    <Button
                        variant="negative"
                        fill
                        onClick={() => {
                            window.location.reload();
                        }}
                        text="Reload"
                    />
                </Contents>
            </ModalMain>
        </Modal>
    );
};

const Heading = styled.div`
    ${heading3}
    color: ${semanticColor('text-primary')};
    text-align: center;
`;

const Body = styled.p`
    ${globalBodyPrimary}
    color: ${semanticColor('text-secondary')};
    margin-bottom: ${rem(16)};
    text-align: center;
`;

const Contents = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: ${rem(16)};
`;

export default ErrorFallback;
