import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_PORTAL_SCALE_FACTOR, InFlightState } from "@internal/tv-auth/client";
export var AccountPanelScreen;
(function(AccountPanelScreen) {
    AccountPanelScreen["Menu"] = "Menu";
    AccountPanelScreen["Account"] = "Account";
    AccountPanelScreen["Subscriptions"] = "Subscriptions";
    AccountPanelScreen["Feedback"] = "Feedback";
})(AccountPanelScreen || (AccountPanelScreen = {}));
export var TvAuthScreen;
(function(TvAuthScreen) {
    TvAuthScreen["List"] = "Connected Providers";
    TvAuthScreen["Picker"] = "Provider List";
    TvAuthScreen["Auth"] = "Auth";
})(TvAuthScreen || (TvAuthScreen = {}));
export var initialState = {
    screen: null,
    tvAuthScreen: TvAuthScreen.List,
    tvAuthState: null,
    tvAuthPlatform: null,
    tvAuthClients: {},
    tvAuthPortalViewport: {
        height: 640,
        width: 360,
        deviceScaleFactor: DEFAULT_PORTAL_SCALE_FACTOR
    },
    tvAuthPortalId: null,
    tvAuthUsername: "",
    tvAuthUsernameError: false,
    tvAuthPassword: "",
    tvAuthPasswordError: false,
    tvAuthErrorMessage: "",
    tvAuthFormReady: false,
    tvAuthPlatformsEnabled: {}
};
var accountPanel = createSlice({
    name: "accountPanel",
    initialState: initialState,
    reducers: {
        showAccountPanel: function showAccountPanel(state, action) {
            if (state.tvAuthState === InFlightState.Authenticating || state.tvAuthState === InFlightState.Portal) {
                state.screen = AccountPanelScreen.Subscriptions;
                return;
            }
            var screen = typeof action.payload === "undefined" ? AccountPanelScreen.Menu : action.payload;
            state.screen = screen;
        },
        hideAccountPanel: function hideAccountPanel(state) {
            state.screen = null;
        },
        setTvAuthScreen: function setTvAuthScreen(state, action) {
            state.tvAuthScreen = action.payload;
        },
        setTvAuthInFlightState: function setTvAuthInFlightState(state, action) {
            state.tvAuthState = action.payload;
        },
        setTvAuthPlatform: function setTvAuthPlatform(state, action) {
            state.tvAuthPlatform = action.payload;
        },
        setTvAuthClients: function setTvAuthClients(state, action) {
            state.tvAuthClients = action.payload;
        },
        setTvAuthPortalViewport: function setTvAuthPortalViewport(state, action) {
            state.tvAuthPortalViewport = action.payload;
        },
        setTvAuthPortalId: function setTvAuthPortalId(state, action) {
            state.tvAuthPortalId = action.payload;
        },
        setTvAuthUsername: function setTvAuthUsername(state, action) {
            state.tvAuthUsername = action.payload;
        },
        setTvAuthUsernameError: function setTvAuthUsernameError(state, action) {
            state.tvAuthUsernameError = action.payload;
        },
        setTvAuthPassword: function setTvAuthPassword(state, action) {
            state.tvAuthPassword = action.payload;
        },
        setTvAuthPasswordError: function setTvAuthPasswordError(state, action) {
            state.tvAuthPasswordError = action.payload;
        },
        setTvAuthErrorMessage: function setTvAuthErrorMessage(state, action) {
            state.tvAuthErrorMessage = action.payload;
        },
        setTvAuthFormReady: function setTvAuthFormReady(state, action) {
            state.tvAuthFormReady = action.payload;
        },
        setEnabledTvAuthPlatforms: function setEnabledTvAuthPlatforms(state, action) {
            state.tvAuthPlatformsEnabled = action.payload;
        }
    }
});
var _actions = accountPanel.actions;
export var showAccountPanel = _actions.showAccountPanel, hideAccountPanel = _actions.hideAccountPanel, setTvAuthScreen = _actions.setTvAuthScreen, setTvAuthInFlightState = _actions.setTvAuthInFlightState, setTvAuthPlatform = _actions.setTvAuthPlatform, setTvAuthClients = _actions.setTvAuthClients, setTvAuthPortalViewport = _actions.setTvAuthPortalViewport, setTvAuthUsername = _actions.setTvAuthUsername, setTvAuthUsernameError = _actions.setTvAuthUsernameError, setTvAuthPassword = _actions.setTvAuthPassword, setTvAuthPasswordError = _actions.setTvAuthPasswordError, setTvAuthErrorMessage = _actions.setTvAuthErrorMessage, setTvAuthFormReady = _actions.setTvAuthFormReady, setTvAuthPortalId = _actions.setTvAuthPortalId, setEnabledTvAuthPlatforms = _actions.setEnabledTvAuthPlatforms;
export default accountPanel.reducer;
