import {
    toggleMessageList,
    toggleMessageNotifications,
    updatePinnedHideTime,
} from '@internal/state/messages';
import {
    localStorageDelete,
    LocalStorageKey,
    localStorageSet,
} from 'features/local-storage/storage';
import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import { AppState } from '../../state/store';

const localStorage =
    (store: MiddlewareAPI<Dispatch<AnyAction>, AppState>) =>
    (next: Dispatch<AnyAction>) =>
    async (action: AnyAction) => {
        switch (action.type) {
            case toggleMessageNotifications.toString():
                localStorageSet(
                    LocalStorageKey.ChatNotificationsDisabled,
                    store.getState().messages.notificationsEnabled
                );
                break;
            case updatePinnedHideTime.toString():
                if (action.payload.time) {
                    localStorageSet(LocalStorageKey.PinnedMessages, Date.now());
                } else {
                    localStorageDelete(LocalStorageKey.PinnedMessages);
                }
                break;
            case toggleMessageList.toString(): {
                if (typeof action.payload === 'undefined') {
                    const open = store.getState().messages.open;
                    localStorageSet(LocalStorageKey.MessagesOpen, !open);
                } else {
                    localStorageSet(LocalStorageKey.MessagesOpen, action.payload);
                }
                break;
            }
            default:
        }

        return next(action);
    };

export default localStorage;
