"use strict";
module.exports.base = {
    primary: {
        100: "#FFFFFF",
        200: "#BABBBF",
        300: "#727272",
        400: "#646464"
    },
    "primary-transparent": {
        25: "rgba(255, 255, 255, 0.25)",
        50: "rgba(255, 255, 255, 0.5)",
        75: "rgba(255, 255, 255, 0.75)"
    },
    secondary: {
        100: "#565656",
        200: "#484848",
        300: "#404040",
        400: "#323232",
        500: "#2B2B2B"
    },
    "secondary-transparent": {
        25: "rgba(43, 43, 43, 0.25)",
        50: "rgba(43, 43, 43, 0.5)",
        75: "rgba(43, 43, 43, 0.75)"
    },
    tertiary: {
        100: "#242424",
        200: "#161616",
        300: "#080808",
        400: "#000000"
    },
    "tertiary-transparent": {
        25: "rgba(0, 0, 0, 0.25)",
        50: "rgba(0, 0, 0, 0.5)",
        75: "rgba(0, 0, 0, 0.75)"
    },
    positive: {
        100: "#5FBF79",
        200: "#284230",
        300: "#122016"
    },
    negative: {
        100: "#DB3236",
        200: "#491816",
        300: "#280E0F",
        400: "#47232E"
    },
    neutral: {
        100: "#F6BF4F",
        200: "#4E432D",
        300: "#3A2F1B"
    },
    accent: {
        100: "#FED4D4",
        200: "#B08A8E",
        250: "#512936",
        275: "#47232E",
        300: "#3A1C25",
        350: "#2E1019",
        400: "#26141A"
    },
    transparent: {
        100: "00FFFFFF"
    }
};
