function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
export var ScheduledEventType;
(function(ScheduledEventType) {
    ScheduledEventType["Game"] = "game";
    ScheduledEventType["Generic"] = "generic";
})(ScheduledEventType || (ScheduledEventType = {}));
export var Sport;
(function(Sport) {
    Sport[Sport["Baseball"] = 1] = "Baseball";
    Sport[Sport["Football"] = 2] = "Football";
    Sport[Sport["Basketball"] = 3] = "Basketball";
    Sport[Sport["Soccer"] = 4] = "Soccer";
    Sport[Sport["Hockey"] = 5] = "Hockey";
    Sport[Sport["Tennis"] = 6] = "Tennis";
    Sport[Sport["Wrestling"] = 7] = "Wrestling";
    Sport[Sport["AutoRacing"] = 8] = "AutoRacing";
    Sport[Sport["Softball"] = 9] = "Softball";
})(Sport || (Sport = {}));
export var SportsLeague;
(function(SportsLeague) {
    SportsLeague[SportsLeague["MLB"] = 1] = "MLB";
    SportsLeague[SportsLeague["NFL"] = 2] = "NFL";
    SportsLeague[SportsLeague["CFB"] = 3] = "CFB";
    SportsLeague[SportsLeague["NBA"] = 4] = "NBA";
    SportsLeague[SportsLeague["MLS"] = 5] = "MLS";
    SportsLeague[SportsLeague["EPL"] = 6] = "EPL";
    SportsLeague[SportsLeague["NHL"] = 7] = "NHL";
    SportsLeague[SportsLeague["WNBA"] = 8] = "WNBA";
    SportsLeague[SportsLeague["NCAAMB"] = 9] = "NCAAMB";
    SportsLeague[SportsLeague["NCAAWB"] = 10] = "NCAAWB";
    SportsLeague[SportsLeague["Nascar"] = 11] = "Nascar";
    SportsLeague[SportsLeague["F1"] = 12] = "F1";
    SportsLeague[SportsLeague["WWE"] = 13] = "WWE";
    SportsLeague[SportsLeague["AEW"] = 14] = "AEW";
    SportsLeague[SportsLeague["AthletesUnlimited"] = 15] = "AthletesUnlimited";
    SportsLeague[SportsLeague["EuroLeague"] = 16] = "EuroLeague";
})(SportsLeague || (SportsLeague = {}));
export var leagues = [
    SportsLeague.MLB,
    SportsLeague.NFL,
    SportsLeague.CFB,
    SportsLeague.NBA,
    SportsLeague.MLS,
    SportsLeague.EPL,
    SportsLeague.NHL,
    SportsLeague.WNBA,
    SportsLeague.NCAAMB,
    SportsLeague.NCAAWB,
    SportsLeague.Nascar,
    SportsLeague.F1,
    SportsLeague.WWE,
    SportsLeague.AEW,
    SportsLeague.AthletesUnlimited,
    SportsLeague.EuroLeague, 
];
var _obj;
export var sportsLeagueNames = (_obj = {}, _defineProperty(_obj, SportsLeague.MLB, "MLB"), _defineProperty(_obj, SportsLeague.NFL, "NFL"), _defineProperty(_obj, SportsLeague.CFB, "College Football"), _defineProperty(_obj, SportsLeague.NBA, "NBA"), _defineProperty(_obj, SportsLeague.MLS, "MLS"), _defineProperty(_obj, SportsLeague.EPL, "EPL"), _defineProperty(_obj, SportsLeague.NHL, "NHL"), _defineProperty(_obj, SportsLeague.WNBA, "WNBA"), _defineProperty(_obj, SportsLeague.NCAAMB, "NCAAMB"), _defineProperty(_obj, SportsLeague.NCAAWB, "NCAAWB"), _defineProperty(_obj, SportsLeague.Nascar, "Nascar"), _defineProperty(_obj, SportsLeague.F1, "F1"), _defineProperty(_obj, SportsLeague.WWE, "WWE"), _defineProperty(_obj, SportsLeague.AEW, "AEW"), _defineProperty(_obj, SportsLeague.AthletesUnlimited, "Athletes Unlimited"), _defineProperty(_obj, SportsLeague.EuroLeague, "EuroLeague"), _obj);
export var sports = [
    Sport.Baseball,
    Sport.Football,
    Sport.Basketball,
    Sport.Soccer,
    Sport.Hockey,
    Sport.Tennis,
    Sport.Wrestling,
    Sport.AutoRacing,
    Sport.Softball, 
];
var _obj1;
export var sportNames = (_obj1 = {}, _defineProperty(_obj1, Sport.Baseball, "Baseball"), _defineProperty(_obj1, Sport.Football, "Football"), _defineProperty(_obj1, Sport.Basketball, "Basketball"), _defineProperty(_obj1, Sport.Soccer, "Soccer"), _defineProperty(_obj1, Sport.Hockey, "Hockey"), _defineProperty(_obj1, Sport.Tennis, "Tennis"), _defineProperty(_obj1, Sport.Wrestling, "Wrestling"), _defineProperty(_obj1, Sport.AutoRacing, "Auto Racing"), _defineProperty(_obj1, Sport.Softball, "Softball"), _obj1);
var _obj2;
export var sportsLeagueMapping = (_obj2 = {}, _defineProperty(_obj2, Sport.Baseball, [
    SportsLeague.MLB
]), _defineProperty(_obj2, Sport.Football, [
    SportsLeague.NFL,
    SportsLeague.CFB
]), _defineProperty(_obj2, Sport.Basketball, [
    SportsLeague.NBA,
    SportsLeague.WNBA,
    SportsLeague.NCAAMB
]), _defineProperty(_obj2, Sport.Soccer, [
    SportsLeague.MLS,
    SportsLeague.EPL
]), _defineProperty(_obj2, Sport.Hockey, [
    SportsLeague.NHL
]), _defineProperty(_obj2, Sport.Tennis, []), _defineProperty(_obj2, Sport.Wrestling, [
    SportsLeague.WWE,
    SportsLeague.AEW
]), _defineProperty(_obj2, Sport.AutoRacing, [
    SportsLeague.Nascar,
    SportsLeague.F1
]), _defineProperty(_obj2, Sport.Softball, []), _obj2);
