function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n        background-image: linear-gradient(\n            to ",
        ",\n            ",
        " 0%,\n            ",
        " 50%,\n            ",
        " 65%,\n            ",
        " 75.5%,\n            ",
        " 82.85%,\n            ",
        " 88%,\n            ",
        " 100%\n        );\n    "
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _taggedTemplateLiteral([
        "\n        background-image: linear-gradient(\n            to ",
        ",\n            ",
        " 0%,\n            ",
        " 15%,\n            ",
        " 50%,\n            ",
        " 85%,\n            ",
        " 100%\n        );\n    "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _taggedTemplateLiteral([
        "\n                          top: 0;\n                      "
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _taggedTemplateLiteral([
        "\n                          bottom: 100%;\n                      "
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _taggedTemplateLiteral([
        "\n                          bottom: 0;\n                      "
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _taggedTemplateLiteral([
        "\n                          top: 100%;\n                      "
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _taggedTemplateLiteral([
        "\n                    top: 0;\n                    bottom: 0;\n                "
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _taggedTemplateLiteral([
        "\n                          left: 0;\n                      "
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _taggedTemplateLiteral([
        "\n                          right: 100%;\n                      "
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _taggedTemplateLiteral([
        "\n                          right: 0;\n                      "
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _taggedTemplateLiteral([
        "\n                          left: 100%;\n                      "
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _taggedTemplateLiteral([
        "\n                    left: 0;\n                    right: 0;\n                "
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _taggedTemplateLiteral([
        "\n                height: ",
        ";\n            "
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
function _templateObject13() {
    var data = _taggedTemplateLiteral([
        "\n                width: ",
        ";\n            "
    ]);
    _templateObject13 = function _templateObject13() {
        return data;
    };
    return data;
}
function _templateObject14() {
    var data = _taggedTemplateLiteral([
        "\n            &.stuck:after {\n                opacity: 1;\n            }\n        "
    ]);
    _templateObject14 = function _templateObject14() {
        return data;
    };
    return data;
}
function _templateObject15() {
    var data = _taggedTemplateLiteral([
        "\n        &:after {\n            content: '';\n            position: absolute;\n            pointer-events: none;\n            z-index: 1;\n\n            ",
        "\n\n            ",
        "\n\n            transition: opacity 50ms ease;\n            opacity: ",
        ";\n\n            ",
        "\n            ",
        "\n\n            ",
        "\n        }\n\n        ",
        "\n    "
    ]);
    _templateObject15 = function _templateObject15() {
        return data;
    };
    return data;
}
import { rem, transparentize } from "polished";
import { css } from "styled-components";
export var fade = function(options) {
    var to = options.to, color = options.color;
    return css(_templateObject(), to, transparentize(0, color), transparentize(0.7, color), transparentize(0.85, color), transparentize(0.925, color), transparentize(0.963, color), transparentize(0.981, color), transparentize(1, color));
};
export var fadeInOut = function(options) {
    var to = options.to, color = options.color;
    return css(_templateObject1(), to, transparentize(1, color), transparentize(0.8, color), transparentize(0.5, color), transparentize(0.8, color), transparentize(1, color));
};
export var fadeOverhang = function(options) {
    var to = options.to, color = options.color, position = options.position, _inset = options.inset, inset = _inset === void 0 ? false : _inset, _showOnStuck = options.showOnStuck, showOnStuck = _showOnStuck === void 0 ? false : _showOnStuck;
    var verticalPosition = function() {
        switch(position){
            case "top":
                return inset ? css(_templateObject2()) : css(_templateObject3());
            case "bottom":
                return inset ? css(_templateObject4()) : css(_templateObject5());
            default:
                return css(_templateObject6());
        }
    };
    var horizontalPosition = function() {
        switch(position){
            case "left":
                return inset ? css(_templateObject7()) : css(_templateObject8());
            case "right":
                return inset ? css(_templateObject9()) : css(_templateObject10());
            default:
                return css(_templateObject11());
        }
    };
    return css(_templateObject15(), "height" in options && options.height && css(_templateObject12(), rem(options.height)), "width" in options && options.width && css(_templateObject13(), rem(options.width)), showOnStuck ? 0 : 1, verticalPosition(), horizontalPosition(), fade({
        color: color,
        to: to
    }), showOnStuck && css(_templateObject14()));
};
