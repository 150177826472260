import { getEventTitle } from "@internal/utils/roomSession";
import { sportsLeagueNames } from "@internal/api/types";
export var getRoomTrackingData = function(room, creatorTier) {
    return {
        id: room.id,
        slug: room.slug,
        urlName: room.urlName,
        name: room.name,
        creatorTier: creatorTier
    };
};
export var getActiveStreamProperties = function(relay, denialReason) {
    var ref, ref1, ref2, ref3;
    if (!relay) return null;
    var event = relay.capturer.scheduledEvent;
    return {
        relayId: relay.id,
        capturerId: relay.capturerID,
        capturerName: (ref = relay.capturer) === null || ref === void 0 ? void 0 : ref.name,
        network: (ref1 = relay.capturer) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.network) === null || ref2 === void 0 ? void 0 : ref2.networkStr,
        eventName: getEventTitle((ref3 = relay.capturer) === null || ref3 === void 0 ? void 0 : ref3.scheduledEvent),
        denialReason: denialReason,
        league: event ? sportsLeagueNames[event.league] : null
    };
};
