import { Breakpoint } from '@internal/ui/css/breakpoints';
import { rem } from 'polished';
import { useState } from 'react';
import { createContext, ReactNode, useContext } from 'react';
import { useMedia } from 'react-use';

export interface AppLayoutContext {
    isMobile: boolean;
    isTouchDevice: boolean;
}

const Context = createContext<AppLayoutContext>({} as AppLayoutContext);

export const useAppLayoutContext = () => useContext(Context);

export interface LayoutProviderProps {
    isPopoutChat?: boolean;
    children: ReactNode;
}

export const AppLayoutProvider = (props: LayoutProviderProps) => {
    const [isTouchDevice] = useState(() =>
        typeof window === 'undefined' ? false : 'ontouchstart' in document.documentElement
    );

    const value: AppLayoutContext = {
        isMobile: !useMedia(`(min-width: ${rem(Breakpoint.Small)})`, true),
        isTouchDevice,
    };

    return <Context.Provider value={value}>{props.children}</Context.Provider>;
};
