import { SocialProvider } from '@internal/auth/types';
import accountPanelReducer, {
    initialState as initialAccountPanelState,
} from '@internal/state/accountPanel';
import chatReducer, { initialState as initialChatState } from '@internal/state/chat';
import contentReducer, { initialState as initialContentState } from '@internal/state/content';
import {
    initialState as initialMessagesState,
    reducer as messagesReducer,
} from '@internal/state/messages';
import { reducer as userReducer, initialState as initialUserState } from './user/userSlice';
import { ChannelType } from '@internal/text-chat/types';
import { configureStore, PreloadedState, StateFromReducersMapObject } from '@reduxjs/toolkit';
import { localStorageGet, LocalStorageKey } from 'features/local-storage/storage';
import Cookie from 'js-cookie';
import { createWrapper } from 'next-redux-wrapper';
import { isProduction } from 'utils/env';
import localStorage from '../features/local-storage/middleware';
import notifications from '../features/notifications/middleware';
import tracking from '../features/tracking/middleware';
import { DeviceInfo, ModalType } from '../typescript/typings';
import { PlaybackCookie } from '../utils/cookies';
import appReducer, { initialState as initialAppState } from './app';
import roomParticipant from './middleware/roomParticipant';
import { initialState as initialRoomState, reducer as roomReducer } from './room';
import { getAuthRedirectParam } from 'features/auth/utils';
import { MembershipFeature } from '@internal/tracking/types';

const getPreloadedState = (): PreloadedState<AppState> => {
    if (typeof window === 'undefined') {
        return {
            accountPanel: initialAccountPanelState,
            app: initialAppState,
            room: initialRoomState,
            user: initialUserState,
            chat: initialChatState,
            content: initialContentState,
            messages: initialMessagesState,
        };
    }

    const url = new URL(window.location.href);
    const magicCredential = url.searchParams.get('magic_credential');
    const customParam = url.searchParams.get('custom');
    const pinnedHideTime = localStorageGet(LocalStorageKey.PinnedMessages, 0);
    const notificationsDisabled = localStorageGet(LocalStorageKey.ChatNotificationsDisabled, false);
    const messagesOpen = localStorageGet(LocalStorageKey.MessagesOpen, true);
    const accessToken = Cookie.get(PlaybackCookie.AccessToken);
    const deviceInfoCookie = Cookie.get(PlaybackCookie.DeviceInfo);
    const deviceInfo: DeviceInfo = deviceInfoCookie ? JSON.parse(deviceInfoCookie) : null;

    return {
        app: {
            ...initialAppState,
            geo: deviceInfo?.geo || {},
            mobile: deviceInfo?.isMobile,
            osName: deviceInfo?.os,
            osVersion: deviceInfo?.osVersion,
            browserName: deviceInfo?.browser,
            browserVersion: deviceInfo?.browserVersion,
            deviceType: deviceInfo?.deviceType ?? '',
            deviceVendor: deviceInfo?.deviceVendor ?? '',
            modal: magicCredential
                ? {
                      type: ModalType.Login,
                      payload: {
                          trigger: getAuthRedirectParam<MembershipFeature>(customParam, 'trigger'),
                          redirect: {
                              magicCredential,
                              provider: getAuthRedirectParam<SocialProvider>(
                                  customParam,
                                  'provider'
                              ),
                              attemptId: getAuthRedirectParam<string>(customParam, 'attemptId'),
                          },
                      },
                  }
                : null,
        },
        user: {
            ...initialUserState,
            accessToken: accessToken ?? '',
        },
        chat: initialChatState,
        content: initialContentState,
        messages: {
            ...initialMessagesState,
            open: deviceInfo?.isMobile ? false : messagesOpen,
            notificationsEnabled: !notificationsDisabled,
            [ChannelType.Room]: {
                ...initialMessagesState[ChannelType.Room],
                pinnedHideTime,
            },
        },
        room: {
            ...initialRoomState,
        },
        accountPanel: {
            ...initialAccountPanelState,
        },
    };
};

const reducer = {
    app: appReducer,
    room: roomReducer,
    content: contentReducer,
    chat: chatReducer,
    user: userReducer,
    messages: messagesReducer,
    accountPanel: accountPanelReducer,
};

export type AppState = StateFromReducersMapObject<typeof reducer>;

const makeStore = () =>
    configureStore({
        reducer,
        middleware: [notifications, roomParticipant, localStorage, tracking],
        devTools: !isProduction(),
        preloadedState: getPreloadedState(),
    });

export type AppStore = ReturnType<typeof makeStore>;
export type AppDispatch = AppStore['dispatch'];
export const wrapper = createWrapper<AppStore>(makeStore, { debug: false });
