export var STAFF_USER_IDS_DEV = new Set([
    "ce5e12fa-6809-4d72-8b68-6801904f38c4",
    "df31dfb1-c336-4a2c-b515-52b353e21818",
    "183c460a-a838-477a-b7a0-f7b407f46ee9",
    "c652bd2d-ed78-4971-9f2b-1acdfe3faa3e",
    "916ef174-2d0f-45ea-9346-519152b8f9fe",
    "af69c26b-42a9-4670-8184-2c7404bc5a6b",
    "5dc259d0-30b7-4bf8-9447-19f400086fef",
    "07745bad-0b75-4e86-8e55-a4809d0cd7af",
    "27b63356-f991-4e64-a600-c390aeeda0e7"
]);
export var STAFF_USER_IDS_PROD = new Set([
    "b4fcd47d-4845-449d-8178-9ac61e20e2c4",
    "dcad1e88-61d3-4916-a8fb-795c4849b832",
    "0f40f6d5-6bd5-4cd0-a5c8-a798e216d6b2",
    "5c9b8203-b514-4855-af55-deefa4bbf364",
    "ca593ab8-d01f-494f-9e8c-4a202cdeda4a",
    "13079f06-6960-4e85-8c43-c85f5244c9f4",
    "69698832-dc4c-4101-aca9-e0b628211130",
    "93db6c53-9571-4f08-bdaa-b36127a546a6",
    "20722b43-99bc-4a96-a77a-428c9f474110",
    "340da4d5-9fe3-4a82-9e96-da6d3bdbb559",
    "70b24a72-fbf2-47a2-8780-9118c2ca7531",
    "c5498878-c320-4a54-bf9e-97202f20ed03"
]);
