import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import classNames from 'classnames';
import Image from 'next/image';

interface AppLoaderProps {
    className?: string;
    visible: boolean;
}

export const AppLoader = (props: AppLoaderProps) => {
    const { className, visible } = props;

    return (
        <AnimatePresence initial={false}>
            {visible && (
                <motion.div
                    className={classNames(
                        'fixed top-0 right-0 bottom-0 left-0 z-10 bg-tertiary-200 flex-center',
                        className
                    )}
                    key="app_loader"
                    initial={{ opacity: 0, transform: 'translateY(100%)' }}
                    animate={{ opacity: 1, transform: 'translateY(0%)' }}
                    exit={{ opacity: 0, transform: 'translateY(100%)' }}
                    transition={{ duration: 0.3 }}
                >
                    <div className="h-16 w-16 overflow-hidden rounded-xl">
                        <Image
                            src="/gif/logo-128-optimized.gif"
                            height={64}
                            width={64}
                            alt="Loading"
                        />
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};
