import { useCurrentUser, useUserUpdate } from '@internal/api-hooks/user';
import { tvAuthSubscriptionsGET } from '@internal/api/tvAuth';
import { User } from '@internal/api/types';
import { userUsageGET } from '@internal/api/user';
import { setEnabledTvAuthPlatforms } from '@internal/state/accountPanel';
import { NotificationType } from '@internal/state/types';
import {
    changeName,
    init,
    login as loginAction,
    setAccessToken,
    setTvSubscriptions,
    setUserFlags,
    updateRoomJoinsCount,
    updateUserFlags,
    userDataFetched,
    UserFlag,
    UserFlags,
} from '@internal/state/user';
import { TrackingContext } from '@internal/tracking/types';
import { SubscriptionTypes, subTypesToNames } from '@internal/tv-auth/platforms';
import { getAnonymousID } from 'features/tracking/middleware';
import Cookie from 'js-cookie';
import { useRouter } from 'next/router';
import React, {
    MutableRefObject,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { toast } from 'react-toastify';
import { dismissModal, identifyUser, showModal, track, trackingQueuePop } from 'state/app';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { AppState } from 'state/store';
import { getRedirect } from '../../routing/redirects';
import { API_HOST } from '../../typescript/api';
import { LoginModalPayload, ModalType, RedirectOptions } from '../../typescript/typings';
import { getData, postData } from '../../utils/api';
import { getAuthCookieOptions, PlaybackCookie } from '../../utils/cookies';

export interface ApiAuthOptions {
    redirect?: string;
}

export type PendingAuth = {
    accessToken: string;
    user: User;
};

export interface IAuthContext {
    user: AppState['user'];
    userRef: MutableRefObject<User>;
    loggedIn: boolean;
    pendingAuth: PendingAuth;
    login: (loginData: User, accessToken: string, redirect?: boolean) => void;
    logout: () => Promise<void>;
    changeUserName: (name: string) => Promise<void>;
    setFlag: (flag: string, value: unknown) => Promise<void>;
    setPendingAuth: (pending: PendingAuth) => void;
    openLogin: (context: TrackingContext, payload?: LoginModalPayload) => void;
    openSignup: (context: TrackingContext, payload?: LoginModalPayload) => void;
}

export const AuthContext = React.createContext({} as IAuthContext);

const AuthProvider = (props: { children: ReactNode }) => {
    const dispatch = useAppDispatch();
    const router = useRouter();
    const user = useAppSelector((state) => state.user);
    const trackingQueue = useAppSelector((state) => state.app.trackingQueue);
    const loggedIn = !!user.accessToken;
    const modalType = useAppSelector((state) => state.app.modal?.type);

    const userRef = useRef(user.instance);

    useCurrentUser({
        enabled: !!user.accessToken,
        onSuccess: (user) => {
            dispatch(init(user));
        },
        onError: () => {
            dispatch(track({ event: 'User Access Token Rejected' }));
        },
    });

    const userUpdate = useUserUpdate({
        onSuccess: (user) => {
            dispatch(init(user));
        },
    });

    if (!user.accessToken && !user.initialized) {
        dispatch(init(null));
    }

    const [pendingAuth, setPendingAuth] = useState<PendingAuth>(null);
    const [magicImport] = useState(() => import('lib/magic'));

    const setAuthCookie = useCallback((accessToken: string) => {
        Cookie.set(PlaybackCookie.AccessToken, accessToken, getAuthCookieOptions());
    }, []);

    const redirectOnLogin = useCallback(
        async (accessToken: string) => {
            const redirect =
                (await getRedirect(router.pathname, accessToken)) || ({} as RedirectOptions);
            if (redirect.to && redirect.loggedIn) {
                router.push(redirect.to, null, { shallow: true });
            }
        },
        [router]
    );

    const login = useCallback(
        (user: User = null, accessToken: string, redirect = true) => {
            if (user) {
                dispatch(loginAction({ user, accessToken }));
            }

            setAuthCookie(accessToken);
            dispatch(setAccessToken(accessToken));

            if (redirect) {
                redirectOnLogin(accessToken);
            }
        },
        [dispatch, setAuthCookie, redirectOnLogin]
    );

    const logout = useCallback(async () => {
        dispatch(track({ event: 'Logout' }));
        const Magic = await magicImport;
        Magic.logout();
        Cookie.remove(PlaybackCookie.AccessToken);
        router.reload();
    }, [dispatch, router, magicImport]);

    useEffect(() => {
        userRef.current = user.instance;
    }, [user.instance]);

    useEffect(() => {
        dispatch(identifyUser({}));
    }, [dispatch, user.id, user.name, user.email, user.avatar?.url]);

    useEffect(() => {
        if (loggedIn) {
            toast.dismiss(NotificationType.MagicEmail);
        }
    }, [dispatch, loggedIn]);

    // Synchronize tv subscriptions with user identity. The user identity should only be updated
    // once tv subscription state has settled. The initial user state will always include an empty
    // array for tv subscriptions, so we need to wait until additional user data has been fetched to
    // avoid resetting tv subscriptions in the user's identity. Pending tv subscriptions are
    // included in global state, so we should skip synchronizing on those changes.
    useEffect(() => {
        const isPending = user.tvSubscriptions.some(
            (sub) => sub.subscription === SubscriptionTypes.Pending
        );

        if (!user.dataFetched || isPending) {
            return;
        }

        const tvSubscriptions = user.tvSubscriptions
            .filter((sub) => sub.subscription !== SubscriptionTypes.None)
            .map((sub) => subTypesToNames[sub.subscription]);

        dispatch(identifyUser({ tvSubscriptions }));
    }, [dispatch, user.dataFetched, user.tvSubscriptions]);

    useEffect(() => {
        if (modalType === ModalType.Login && loggedIn) {
            const handleRouteChangeComplete = () => {
                dispatch(dismissModal());
            };

            router.events.on('routeChangeComplete', handleRouteChangeComplete);

            return () => {
                router.events.off('routeChangeComplete', handleRouteChangeComplete);
            };
        }
    }, [dispatch, router, modalType, loggedIn]);

    useEffect(() => {
        if (user.id && user.accessToken) {
            const fetchAdditionalData = async () => {
                const usageRequest = userUsageGET(user.accessToken, API_HOST);
                const tvSubscriptionsRequest = tvAuthSubscriptionsGET(user.accessToken, API_HOST);
                const tvPlatformsRequest = getData<UserFlags>(`/api/tv-auth/providers`);
                const flagsRequest = getData<UserFlags>(`/api/user/${user.id}`, user.accessToken);
                const [usage, tvSubscriptions, tvPlatforms, flags] = await Promise.all([
                    usageRequest,
                    tvSubscriptionsRequest,
                    tvPlatformsRequest,
                    flagsRequest,
                ]);

                dispatch(setUserFlags(flags));
                dispatch(setTvSubscriptions(tvSubscriptions));
                dispatch(setEnabledTvAuthPlatforms(tvPlatforms));
                dispatch(updateRoomJoinsCount(usage.roomJoinsCount));
                dispatch(userDataFetched());
            };

            fetchAdditionalData();
        }
    }, [user.accessToken, user.id, dispatch]);

    useEffect(() => {
        const initSentryUser = async () => {
            const Sentry = await import('@sentry/nextjs');
            Sentry.setUser({
                id: user.id || getAnonymousID(),
                username: user.name,
                email: user.email,
            });
        };

        if (user.id) {
            initSentryUser();
        }
    }, [user.id, user.name, user.email]);

    // Process tracking actions queued while user was
    // initializing
    useEffect(() => {
        if (user.initialized && trackingQueue.length > 0) {
            const queuedAction = trackingQueue[0];
            dispatch(queuedAction);
            dispatch(trackingQueuePop());
        }
    }, [user.initialized, trackingQueue, dispatch]);

    const changeUserName = useCallback(
        async (name) => {
            dispatch(
                track({
                    event: 'Change Username',
                    updatedName: name,
                })
            );

            dispatch(changeName(name));
            userUpdate.mutate({ name });
        },
        [dispatch, userUpdate, user.name, user.accessToken]
    );

    const setFlag = useCallback(
        async (flag: string, value: unknown) => {
            const userFlags = user.flags;
            dispatch(updateUserFlags({ ...userFlags, [flag]: value }));
            try {
                await postData<UserFlags>(
                    `/api/user/${user.id}`,
                    {
                        [flag]: value,
                    },
                    user.accessToken
                );
            } catch (e) {
                dispatch(updateUserFlags(userFlags));
            }
        },
        [dispatch, user.id, user.accessToken, user.flags]
    );

    const openLogin = (context: TrackingContext = null, payload?: LoginModalPayload) => {
        dispatch(showModal({ type: ModalType.Login, payload }));
        dispatch(
            track({
                event: 'Click Log In',
                context,
            })
        );
    };

    const openSignup = (context: TrackingContext = null, payload?: LoginModalPayload) => {
        dispatch(showModal({ type: ModalType.Login, payload }));
        dispatch(
            track({
                event: 'Click Sign Up',
                context,
            })
        );
    };

    const auth = {
        user,
        userRef,
        loggedIn,
        pendingAuth,
        login,
        logout,
        changeUserName,
        setFlag,
        setPendingAuth,
        openLogin,
        openSignup,
    };

    return <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>;
};

export default AuthProvider;
