export var RoomStates;
(function(RoomStates) {
    RoomStates[RoomStates["Loading"] = 0] = "Loading";
    RoomStates[RoomStates["LoadingRoom"] = 1] = "LoadingRoom";
    RoomStates[RoomStates["Connecting"] = 2] = "Connecting";
    RoomStates[RoomStates["Reconnecting"] = 3] = "Reconnecting";
    RoomStates[RoomStates["VideoPending"] = 4] = "VideoPending";
    RoomStates[RoomStates["VideoLoading"] = 5] = "VideoLoading";
    RoomStates[RoomStates["SubscribeError"] = 6] = "SubscribeError";
    RoomStates[RoomStates["RoomNotFound"] = 7] = "RoomNotFound";
    RoomStates[RoomStates["OpenInBrowser"] = 8] = "OpenInBrowser";
    RoomStates[RoomStates["Disconnected"] = 9] = "Disconnected";
    RoomStates[RoomStates["Error"] = 10] = "Error";
    RoomStates[RoomStates["Queued"] = 11] = "Queued";
})(RoomStates || (RoomStates = {}));
