import { createAction } from "@reduxjs/toolkit";
export var onOpen = createAction("participant/onOpen");
export var onDisconnect = createAction("participant/onDisconnect");
export var onRoomState = createAction("participant/onRoomState");
export var onRemoteStream = createAction("participant/onRemoteStream");
export var onRemoteParticipantUnregister = createAction("participant/onRemoteParticipantUnregister");
export var onRemoteParticipantUpdate = createAction("participant/onRemoteParticipantUpdate");
export var onRemoteParticipantsUpdate = createAction("participant/onRemoteParticipantsUpdate");
export var onVoteStreakState = createAction("participant/onVoteStreakState");
export var onVoteState = createAction("participant/onVoteState");
export var onParticipantMute = createAction("participant/onParticipantMute");
export var onRoomMutedUpdate = createAction("participant/onRoomMutedUpdate");
export var onSubsQueueSizeUpdate = createAction("participant/onSubsQueueSizeUpdate");
export var onStageAccept = createAction("participant/onStageAccept");
export var onStageInvite = createAction("participant/onStageInvite");
export var onStageKick = createAction("participant/onStageKick");
export var onStageRSVP = createAction("participant/onStageRSVP");
export var onStageRequest = createAction("participant/onStageRequest");
export var onStageUpdate = createAction("participant/onStageUpdate");
export var onQualityIndicator = createAction("participant/onQualityIndicator");
export var onRoomSessionUpdate = createAction("participant/onRoomSessionUpdate");
export var onContentStreamUpdate = createAction("participant/contentStreamUpdate");
export var onRelayResolution = createAction("participant/relayResolution");
