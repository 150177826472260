function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n    outline: none;\n    box-shadow: 0px 0px 0px ",
        " ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _taggedTemplateLiteral([
        "\n    &:focus-visible {\n        ",
        "\n    }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _taggedTemplateLiteral([
        "\n    :focus-within {\n        outline: none;\n        box-shadow: none;\n    }\n\n    :has(:focus-visible) {\n        ",
        "\n    }\n\n    ",
        ":focus {\n        ",
        "\n    }\n\n    ",
        ":focus:not(:focus-visible) {\n        outline: none;\n        box-shadow: none;\n    }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import { css } from "styled-components";
import { rem } from "polished";
import { Base } from "../palette/base";
export var greenFocusRing = css(_templateObject(), rem(1), Base.Positive100);
export var focusVisible = css(_templateObject1(), greenFocusRing);
// TODO: use ts to enforce valid html element string
export var focusVisibleWithin = function(childElement) {
    return css(_templateObject2(), greenFocusRing, childElement, greenFocusRing, childElement);
};
