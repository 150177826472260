import { PermissionType } from '@internal/api/types';
import { Signal, SignalType } from '@internal/room-client';
import { getParticipant, updateContentStream, updateStatus } from '@internal/room-client-hooks';
import {
    leftStage,
    stageInviteRevoked,
    stageRequestAccepted,
    stageRequestDenied,
} from '@internal/state/chat';
import { setRemoteStream } from '@internal/state/content';
import {
    onDisconnect,
    onOpen,
    onParticipantMute,
    onQualityIndicator,
    onRelayResolution,
    onContentStreamUpdate,
    onRemoteParticipantUnregister,
    onRemoteParticipantUpdate,
    onRemoteStream,
    onRoomMutedUpdate,
    onRoomState,
    onStageAccept,
    onStageInvite,
    onStageKick,
    onStageRequest,
    onStageRSVP,
    onStageUpdate,
    onSubsQueueSizeUpdate,
    onVoteState,
    onVoteStreakState,
    onRemoteParticipantsUpdate,
} from '@internal/state/participant';
import {
    removeParticipant,
    setRelayResolution,
    setRoomState,
    setSubsQueueSize,
    updateMuted,
    updateParticipant,
    updateParticipants,
    updateQualityIndicator,
    updateStage,
    updateVoteState,
    updateVoteStreakState,
} from '@internal/state/room';
import { NotificationType } from '@internal/state/types';
import { Middleware } from 'redux';
import { AppState } from 'state/store';
import { ModalType } from '../../typescript/typings';
import { showModal, showNotification, track } from '../app';

export const roomParticipant: Middleware<never, AppState> = (store) => (next) => async (action) => {
    switch (action.type) {
        case onOpen.toString():
            updateStatus('connected');
            break;
        case onDisconnect.toString():
            updateStatus('disconnected');
            store.dispatch(showModal({ type: ModalType.RoomDisconnected }));
            break;
        case onRoomState.toString(): {
            const payload = (action as ReturnType<typeof onRoomState>).payload;

            store.dispatch(setRoomState(action.payload));
            store.dispatch(updateStage(payload.stage));
            break;
        }
        case onRemoteStream.toString():
            store.dispatch(setRemoteStream(action.payload));
            break;
        case onRemoteParticipantUnregister.toString(): {
            store.dispatch(removeParticipant(action.payload.participantID));
            break;
        }
        case onRemoteParticipantUpdate.toString(): {
            store.dispatch(updateParticipant(action.payload.participant));
            const localParticipant = getParticipant();
            if (!localParticipant || localParticipant.id === action.payload.participant.id) {
                updateParticipant(action.payload.participant);
            }
            break;
        }
        case onRemoteParticipantsUpdate.toString(): {
            store.dispatch(updateParticipants(action.payload.participants));
            break;
        }
        case onVoteStreakState.toString(): {
            const { participant, winner } = <Signal<SignalType.VoteStreakState>>action.payload;
            if (participant?.id === store.getState().room.participantID) {
                store.dispatch(track({ event: 'Vote Streak Started', winner }));
            }

            store.dispatch(updateVoteStreakState(action.payload));
            break;
        }
        case onVoteState.toString():
            store.dispatch(updateVoteState(action.payload));
            break;
        case onParticipantMute.toString(): {
            break;
        }
        case onRoomMutedUpdate.toString(): {
            const payload = (action as ReturnType<typeof onRoomMutedUpdate>).payload;
            const userID = store.getState().user.id;
            const prevMuted = store.getState().room.muted;
            const isMod = store.getState().room.instance.permissions[PermissionType.Speak];

            if (!(userID in prevMuted) && userID in payload.muted) {
                store.dispatch(
                    showNotification({ type: NotificationType.MicMuted, payload: { isMod } })
                );
            }

            store.dispatch(updateMuted(action.payload.muted));
            break;
        }
        case onSubsQueueSizeUpdate.toString(): {
            store.dispatch(setSubsQueueSize(action.payload.size));
            break;
        }
        case onStageInvite.toString(): {
            const payload = (action as ReturnType<typeof onStageInvite>).payload;
            store.dispatch(stageInviteRevoked());
            const stage = !store.getState().room.settings.openSpeak;
            const localParticipant = getParticipant();
            const isMod = localParticipant?.permissions?.find(
                (p) => p.permission === PermissionType.StageInvite && p.enabled
            );

            if (stage && !isMod && !payload.revoke) {
                store.dispatch(showNotification({ type: NotificationType.StageInvite }));
            }

            break;
        }
        case onStageRequest.toString():
            break;
        case onStageAccept.toString(): {
            store.dispatch(action.payload.accepted ? stageRequestAccepted() : stageRequestDenied());
            const stage = !store.getState().room.settings.openSpeak;
            const localParticipant = getParticipant();
            const isMod = localParticipant?.permissions?.find(
                (p) => p.permission === PermissionType.StageInvite && p.enabled
            );

            if (stage && !isMod && action.payload.accepted) {
                store.dispatch(showNotification({ type: NotificationType.StageInvite }));
            }

            break;
        }
        case onStageUpdate.toString(): {
            const payload = (action as ReturnType<typeof onStageUpdate>).payload;
            const userID = store.getState().user.id;
            const prevStage = store.getState().room.stage.members;
            store.dispatch(updateStage(payload.stage));

            if (userID in prevStage && !(userID in payload.stage.members)) {
                store.dispatch(leftStage());
            }

            break;
        }
        case onStageKick.toString():
            break;
        case onStageRSVP.toString():
            break;
        case onQualityIndicator.toString(): {
            const payload = (action as ReturnType<typeof onQualityIndicator>).payload;
            store.dispatch(updateQualityIndicator(payload));
            break;
        }
        case onContentStreamUpdate.toString(): {
            const payload = (action as ReturnType<typeof onContentStreamUpdate>).payload;
            updateContentStream(payload.contentStream);
            break;
        }
        case onRelayResolution.toString(): {
            const payload = (action as ReturnType<typeof onRelayResolution>).payload;
            store.dispatch(setRelayResolution(payload));
            break;
        }
        default:
    }

    return next(action);
};

export default roomParticipant;
