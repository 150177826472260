import { useEffect } from 'react';
import { setHasUserInteracted } from 'state/app';
import { useAppDispatch, useAppSelector } from 'state/hooks';

/**
 * Use this hook to track whether the user has interacted with the page
 */
const userFirstUserInteraction = () => {
    const dispatch = useAppDispatch();
    const autoplayUnmuted = useAppSelector((state) => state.app.hasUserInteracted);

    useEffect(() => {
        if (!autoplayUnmuted) {
            const handleClick = () => {
                requestAnimationFrame(() => {
                    dispatch(setHasUserInteracted());
                });
            };

            document.addEventListener('click', handleClick);
            document.addEventListener('touchstart', handleClick);

            return () => {
                document.removeEventListener('click', handleClick);
                document.removeEventListener('touchsart', handleClick);
            };
        }
    }, [dispatch, autoplayUnmuted]);
};

export default userFirstUserInteraction;
