function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function _iterableToArray(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _nonIterableSpread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
import { transparentize } from "polished";
import memoize from "lodash.memoize";
export var easingGradient = memoize(function(color, to) {
    var alpha = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 1;
    return "linear-gradient(\n            to ".concat(to, ",\n            ").concat(transparentize(withAlpha(0, alpha), color), " 0%,\n            ").concat(transparentize(withAlpha(0.7, alpha), color), " 50%,\n            ").concat(transparentize(withAlpha(0.85, alpha), color), " 65%,\n            ").concat(transparentize(withAlpha(0.925, alpha), color), " 75.5%,\n            ").concat(transparentize(withAlpha(0.963, alpha), color), " 82.85%,\n            ").concat(transparentize(withAlpha(0.981, alpha), color), " 88%,\n            ").concat(transparentize(withAlpha(1, alpha), color), " 100%\n        )");
});
function withAlpha(transparency, alpha) {
    return transparency + (1 - transparency) * (1 - alpha);
}
export var inlineEasingGradient = function() {
    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
        args[_key] = arguments[_key];
    }
    return {
        backgroundImage: easingGradient.apply(void 0, _toConsumableArray(args))
    };
};
