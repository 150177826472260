import { useEffect } from 'react';
import { useIdle as useIdleHook } from 'react-use';
import { useAppDispatch } from 'state/hooks';
import { setIdle } from 'state/app';

const IDLE_TIMEOUT = 2 * 60 * 1000; // 2 minutes

const useIdle = () => {
    const idle = useIdleHook(IDLE_TIMEOUT);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setIdle(idle));
    }, [dispatch, idle]);
};

export default useIdle;
