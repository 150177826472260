import { semantic } from "./semanticPalette";
export var semanticColor = function(name) {
    return semantic[name];
};
export var actionBg = {
    primary: "bg-action-primary",
    secondary: "bg-action-secondary",
    negative: "bg-action-negative",
    neutral: "bg-action-neutral",
    positive: "bg-action-positive",
    negativeLoud: "bg-action-negativeLoud",
    accent: "bg-action-accent",
    tertiaryTransparent: "bg-action-tertiary-transparent"
};
export var actionText = {
    primary: "text-action-primary",
    secondary: "text-action-secondary",
    negative: "text-action-negative",
    neutral: "text-action-neutral",
    positive: "text-action-positive",
    negativeLoud: "text-action-negativeLoud",
    accent: "text-action-accent",
    tertiaryTransparent: "text-tertiary-transparent"
};
export var actionBgHover = {
    primary: "hover:bg-action-primary--hover group-hover:bg-action-primary--hover",
    secondary: "hover:bg-action-secondary--hover group-hover:bg-action-secondary--hover",
    negative: "hover:bg-action-negative--hover group-hover:bg-action-negative--hover",
    neutral: "hover:bg-action-neutral--hover group-hover:bg-action-neutral--hover",
    positive: "hover:bg-action-positive--hover group-hover:bg-action-positive--hover",
    negativeLoud: "hover:bg-action-negativeLoud--hover group-hover:bg-action-negativeLoud--hover",
    accent: "hover:bg-action-accent--hover group-hover:bg-action-accent--hover",
    tertiaryTransparent: "hover:bg-action-tertiary-transparent--hover group-hover:bg-action-tertiary-transparent--hover"
};
export var actionTextHover = {
    primary: "hover:text-action-primary--hover group-hover:text-action-primary--hover",
    secondary: "hover:text-action-secondary--hover group-hover:text-action-secondary--hover",
    negative: "hover:text-action-negative--hover group-hover:text-action-negative--hover",
    neutral: "hover:text-action-neutral--hover group-hover:text-action-neutral--hover",
    positive: "hover:text-action-positive--hover group-hover:text-action-positive--hover",
    negativeLoud: "hover:text-action-negativeLoud--hover group-hover:text-action-negativeLoud--hover",
    accent: "hover:text-action-accent--hover group-hover:text-action-accent--hover",
    tertiaryTransparent: "hover:text-tertiary-transparent--hover group-hover:text-tertiary-transparent--hover"
};
export var actionBgActive = {
    primary: "active:bg-action-primary--active",
    secondary: "active:bg-action-secondary--active",
    negative: "active:bg-action-negative--active",
    neutral: "active:bg-action-neutral--active",
    positive: "active:bg-action-positive--active",
    negativeLoud: "active:bg-action-negativeLoud--active",
    accent: "active:bg-action-accent--active",
    tertiaryTransparent: "active:bg-action-tertiary-transparent--active"
};
export var actionTextActive = {
    primary: "active:text-action-primary--active",
    secondary: "active:text-action-secondary--active",
    negative: "active:text-action-negative--active",
    neutral: "active:text-action-neutral--active",
    positive: "active:text-action-positive--active",
    negativeLoud: "active:text-action-negativeLoud--active",
    accent: "active:text-action-accent--active",
    tertiaryTransparent: "active:text-tertiary-transparent--active"
};
export var actionBgDisabled = {
    primary: "disabled:bg-action-primary--disabled",
    secondary: "disabled:bg-action-secondary--disabled",
    negative: "disabled:bg-action-negative--disabled",
    neutral: "disabled:bg-action-neutral--disabled",
    positive: "disabled:bg-action-positive--disabled",
    negativeLoud: "disabled:bg-action-negativeLoud--disabled",
    accent: "disabled:bg-action-accent--disabled",
    tertiaryTransparent: "disabled:bg-action-tertiary-transparent--disabled"
};
export var actionTextDisabled = {
    primary: "disabled:text-action-primary--disabled",
    secondary: "disabled:text-action-secondary--disabled",
    negative: "disabled:text-action-negative--disabled",
    neutral: "disabled:text-action-neutral--disabled",
    positive: "disabled:text-action-positive--disabled",
    negativeLoud: "disabled:text-action-negativeLoud--disabled",
    accent: "disabled:text-action-accent--disabled",
    tertiaryTransparent: "disabled:text-tertiary-transparent--disabled"
};
export var actionBgDisabledBase = {
    primary: "bg-action-primary--disabled",
    secondary: "bg-action-secondary--disabled",
    negative: "bg-action-negative--disabled",
    neutral: "bg-action-neutral--disabled",
    positive: "bg-action-positive--disabled",
    negativeLoud: "bg-action-negativeLoud--disabled",
    accent: "bg-action-accent--disabled",
    tertiaryTransparent: "bg-action-tertiary-transparent--disabled"
};
export var actionTextDisabledBase = {
    primary: "text-action-primary--disabled",
    secondary: "text-action-secondary--disabled",
    negative: "text-action-negative--disabled",
    neutral: "text-action-neutral--disabled",
    positive: "text-action-positive--disabled",
    negativeLoud: "text-action-negativeLoud--disabled",
    accent: "text-action-accent--disabled",
    tertiaryTransparent: "text-tertiary-transparent--disabled"
};
export var statusFg = {
    primary: "text-status-primary",
    secondary: "text-status-secondary",
    positive: "text-status-positive",
    negative: "text-status-negative",
    negativeLoud: "text-status-primary",
    neutral: "text-status-neutral",
    neutralLoud: "text-status-neutralLoud"
};
export var statusBg = {
    primary: "bg-status-primary",
    secondary: "bg-status-secondary",
    positive: "bg-status-positive",
    negative: "bg-status-negative",
    negativeLoud: "bg-status-primary",
    neutral: "bg-status-neutral",
    neutralLoud: "bg-status-neutralLoud"
};
