function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import { createSlice } from "@reduxjs/toolkit";
import { init as initUser } from "../user";
import { exitRoom, joinRoom, leaveRoom, setRoom, updateRoomName, updateStage } from "./sharedActions";
export var initialState = {
    instance: null,
    previous: null,
    settings: {},
    roomType: null,
    dataLoaded: false,
    activeSpeakerID: "",
    relayResolution: null,
    creating: false,
    creatorID: "",
    downVotes: {},
    id: "",
    joined: false,
    losers: {},
    name: "",
    participantID: "",
    participants: {
        byId: {}
    },
    userParticipants: {},
    qualityIndicatorState: {},
    upVotes: {},
    urlName: null,
    winners: {},
    voteState: {},
    voteStreakState: {},
    stage: {
        members: {},
        pendingInvites: {},
        pendingRequests: {}
    },
    muted: {},
    maxSubs: null,
    subsQueueSize: null
};
export var extraReducers = function(builder) {
    builder.addCase(initUser, function(state, action) {
        if (!state.joined) {
            return;
        }
    }).addCase(updateRoomName, function(state, action) {
        var _payload = action.payload, roomID = _payload.roomID, name = _payload.name;
        if (roomID === state.id) {
            state.name = name;
            state.instance.room.name = name;
        }
    }).addCase(setRoom, function(state, action) {
        var _room = action.payload.room, id = _room.id, settings = _room.settings, userID = _room.userID, urlName = _room.urlName, name = _room.name, roomType = _room.roomType;
        state.previous = state.instance;
        state.instance = action.payload;
        state.roomType = roomType;
        state.settings = settings;
        state.creating = false;
        state.id = id;
        state.creatorID = userID;
        state.urlName = urlName;
        state.name = name;
    }).addCase(updateStage, function(state, action) {
        state.stage = action.payload;
    }).addCase(joinRoom, function(state) {
        state.joined = true;
    }).addCase(leaveRoom, function(state) {
        state.joined = false;
        state.guestName = null;
    }).addCase(exitRoom, function(state) {
        return _objectSpread({}, initialState, {
            previous: state.instance
        });
    });
    return builder;
};
export var roomSlice = createSlice({
    name: "room",
    initialState: initialState,
    reducers: {
        createRoom: function createRoom(state) {
            state.creating = true;
        },
        cancelCreateRoom: function cancelCreateRoom(state) {
            state.creating = false;
        },
        setID: function setID(state, action) {
            state.id = action.payload;
        },
        setParticipantID: function setParticipantID(state, action) {
            state.participantID = action.payload;
        },
        setRoomState: function setRoomState(state, action) {
            return _objectSpread({}, state, action.payload, {
                dataLoaded: true,
                participants: {
                    byId: action.payload.participants
                },
                userParticipants: action.payload.userParticipantIDs
            });
        },
        setSubsQueueSize: function setSubsQueueSize(state, action) {
            state.subsQueueSize = action.payload;
        },
        setActiveSpeakerID: function setActiveSpeakerID(state, action) {
            state.activeSpeakerID = action.payload;
        },
        updateSettings: function updateSettings(state, action) {
            state.settings = action.payload;
        },
        updateParticipant: function updateParticipant(state, action) {
            updateParticipantHelper(state, action.payload);
        },
        updateParticipants: function updateParticipants(state, action) {
            action.payload.forEach(function(participant) {
                updateParticipantHelper(state, participant);
            });
        },
        removeParticipant: function removeParticipant(state, action) {
            var current = state.participants.byId[action.payload];
            // Remove member references if this was the only active participant
            // for the corresponding user
            if (current && !current.dupeUser && !current.ghost && state.userParticipants[current.userID] === action.payload) {
                delete state.userParticipants[current.userID];
            }
            delete state.participants.byId[action.payload];
        },
        updateVoteStreakState: function updateVoteStreakState(state, action) {
            var _payload = action.payload, winners = _payload.winners, losers = _payload.losers;
            state.winners = winners;
            state.losers = losers;
        },
        updateVoteState: function updateVoteState(state, action) {
            var _payload = action.payload, upVotes = _payload.upVotes, downVotes = _payload.downVotes;
            state.upVotes = upVotes;
            state.downVotes = downVotes;
        },
        updateMuted: function updateMuted(state, action) {
            state.muted = action.payload;
        },
        updateQualityIndicator: function updateQualityIndicator(state, action) {
            var _payload = action.payload, indicatorType = _payload.indicatorType, active = _payload.active;
            state.qualityIndicatorState[indicatorType] = active;
        },
        setWsURL: function setWsURL(state, action) {
            if (state.instance) {
                state.instance.room.roomServerWSURL = action.payload;
            }
        },
        setRelayResolution: function setRelayResolution(state, action) {
            state.relayResolution = action.payload;
        }
    },
    extraReducers: extraReducers
});
var updateParticipantHelper = function(state, participant) {
    var id = participant.id, userID = participant.userID, dupeUser = participant.dupeUser, ghost = participant.ghost;
    if (dupeUser || ghost) {
        return;
    }
    state.participants.byId[id] = participant;
    // Add user-participant mapping
    if (userID) {
        state.userParticipants[userID] = id;
    }
};
var _actions = roomSlice.actions;
export var createRoom = _actions.createRoom, cancelCreateRoom = _actions.cancelCreateRoom, setID = _actions.setID, setParticipantID = _actions.setParticipantID, setActiveSpeakerID = _actions.setActiveSpeakerID, setRoomState = _actions.setRoomState, setSubsQueueSize = _actions.setSubsQueueSize, updateSettings = _actions.updateSettings, updateParticipant = _actions.updateParticipant, updateParticipants = _actions.updateParticipants, removeParticipant = _actions.removeParticipant, updateVoteStreakState = _actions.updateVoteStreakState, updateVoteState = _actions.updateVoteState, updateMuted = _actions.updateMuted, updateQualityIndicator = _actions.updateQualityIndicator, setWsURL = _actions.setWsURL, setRelayResolution = _actions.setRelayResolution;
export default roomSlice.reducer;
