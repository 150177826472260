function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
export var RoomType;
(function(RoomType) {
    RoomType[RoomType["Standard"] = 0] = "Standard";
    RoomType[RoomType["Community"] = 1] = "Community";
})(RoomType || (RoomType = {}));
export var SocialPlatform;
(function(SocialPlatform) {
    SocialPlatform[SocialPlatform["Website"] = 1] = "Website";
    SocialPlatform[SocialPlatform["Twitter"] = 2] = "Twitter";
    SocialPlatform[SocialPlatform["YouTube"] = 3] = "YouTube";
    SocialPlatform[SocialPlatform["Discord"] = 4] = "Discord";
    SocialPlatform[SocialPlatform["Facebook"] = 5] = "Facebook";
    SocialPlatform[SocialPlatform["Instagram"] = 6] = "Instagram";
})(SocialPlatform || (SocialPlatform = {}));
var _obj;
export var socialPlatformLabels = (_obj = {}, _defineProperty(_obj, SocialPlatform.Website, "Website"), _defineProperty(_obj, SocialPlatform.Twitter, "Twitter"), _defineProperty(_obj, SocialPlatform.YouTube, "YouTube"), _defineProperty(_obj, SocialPlatform.Discord, "Discord"), _defineProperty(_obj, SocialPlatform.Facebook, "Facebook"), _defineProperty(_obj, SocialPlatform.Instagram, "Instagram"), _obj);
export var RoomSlugStatus;
(function(RoomSlugStatus) {
    RoomSlugStatus[RoomSlugStatus["Available"] = 1] = "Available";
    RoomSlugStatus[RoomSlugStatus["Taken"] = 2] = "Taken";
    RoomSlugStatus[RoomSlugStatus["Invalid"] = 3] = "Invalid";
})(RoomSlugStatus || (RoomSlugStatus = {}));
export var URL_NAME_REGEX = /[0-9a-z]{20}/;
